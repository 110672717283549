import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../Section/Section"

import "./Offering.scss"

export default function Offering() {
  const { t } = useTranslation()

  return (
    <div className="offering">
      <Section>
        <div className="section-content">
          <h2 id="offering" className="text-left">
            {t("Offering.title")}
          </h2>
          <h3 className="text-left">{t("Offering.subtitle_1")}</h3>
          <p className="text-left">{t("Offering.description_1")}</p>
        </div>
        <StaticImage
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          src="../../../images/dayra-app-phone.png"
          alt="A phone which is using the Dayra app."
          className="phone-image-wrapper"
          imgClassName="phone-image"
        />
        <div className="section-content mirrored">
          <h3 className="text-left">{t("Offering.subtitle_2")}</h3>
          <p className="text-left">{t("Offering.description_2")}</p>
        </div>
        <StaticImage
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          src="../../../images/productiveLoan.png"
          imgClassName="illustration-kiosk"
          className="illustration-kiosk-wrapper"
          alt="A kiosk, the owner of which is using the Dayra app."
        ></StaticImage>

        <div className="section-content">
          <h3 className="text-left">{t("Offering.subtitle_3")}</h3>
          <p className="text-left">{t("Offering.description_3")}</p>
        </div>

        <StaticImage
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          src="../../../images/Bills.png"
          imgClassName="illustration-bills"
          className="illustration-bills-wrapper"
          alt="A woman paying her bills using the Dayra app."
        ></StaticImage>

        <div className="section-content mirrored align-center">
          <h3 className="text-left">{t("Offering.subtitle_4")}</h3>
          <p className="text-left">{t("Offering.description_4")}</p>
        </div>
        <StaticImage
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          src="../../../images/Marketplace.png"
          imgClassName="phone-image-overlay"
          className="phone-image-overlay-wrapper"
          alt="A phone which using the Marketplace feature in the Dayra app."
        ></StaticImage>
      </Section>
    </div>
  )
}
